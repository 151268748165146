<template>
    <div class='account_manage'>
        <!-- 标题 -->
        <div class="vue_title">会员组</div>
        <div class="account_main">
            <!--  -->
            <div class="main_condition">
                <div class="cond_left">
                    <el-button type="primary" class="btnBgColor_blue_empty" @click="addEditBtn(1,'')" size="small" icon="el-icon-plus">新增</el-button>
                </div>
            </div>
            <!--  -->
            <div class="main_table">
                <el-table :data="list" v-loading="loading" style="width: 100%" ref="multipleTable" header-cell-class-name="table-header" cell-class-name="cell-class">
                    <template slot="empty">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </template>
                    <el-table-column prop="id" label="ID" align="center" width="60"></el-table-column>
                    <el-table-column prop="name" label="名称" width="200" show-overflow-tooltip></el-table-column>
                    <el-table-column label="封面">
                        <template slot-scope="scope">
                            <el-image style="width:55px; height: 55px" :src="scope.row.cover" :preview-src-list="srcList">
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <el-switch style="line-height: 40px;" v-model="scope.row.status" :active-value='1' :inactive-value='0' :key="scope.row.id" @change="changeSwitch($event,scope.row,scope.$index)"></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="添加时间"></el-table-column>
                    <el-table-column prop="update_time" label="修改时间"></el-table-column>
                    <el-table-column class-name="last_class" label="操作" fixed="right" width="150">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="addEditBtn(2,scope.row)">编辑</el-button>
                            <el-button type="text" size="small" @click="delBtn(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="page_box" v-if="total" style="padding-bottom: 20px;">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <!-- 删除提示 -->
        <point-out ref='dialogTip' dialogTitle="提示" type="accountDel" @close='closeListBtn' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">删除该分组后将不在当前列表显示，确定要删除吗？</div>
            </div>
        </point-out>
        <!-- 新增/编辑 -->
        <group-add ref="dialog" :type="type" @submit='submitBtn'></group-add>
    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//提示
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import GroupAdd from '../components/group_add/index.vue'//新增/编辑
export default {
    components: { PointOut, EmptyData, PagingPage, GroupAdd },
    props: {},
    data() {
        return {
            title: '',
            list: [],
            loading: false,
            page: 1,
            limit: 10,
            total: 0,
            // 删除
            del_id: '',
            srcList: [],
            type: 1,
            show: "",
            style: "",
        }
    },
    computed: {},
    watch: {},
    created() {
        this.getList()
    },
    mounted() { },
    methods: {
        // 列表
        getList() {
            var that = this
            that.loading = true
            var params = {
                page: that.page,
                limit: that.limit,
            }
            that.$memberApi.memberGroupList(params).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    that.list = res.result?.data
                    that.total = res.result?.total
                    let arr = []
                    that.list.forEach(ele => {
                        arr.push(ele.cover)
                    })
                    this.srcList = arr
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 新增/编辑
        addEditBtn(type, info) {
            this.type = type
            this.$refs.dialog.openDialogBtn(info)
        },
        // 删除
        delBtn(id) {
            this.del_id = id
            this.$refs.dialogTip.openDialogBtn()
        },
        closeListBtn() {
            var that = this
            if (that.del_id !== '') {
                that.$memberApi.memberGroupDel(this.del_id).then(res => {
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.getList()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 修改状态
        changeSwitch(val, info, index) {
            var that = this
            var params = {
                id: info.id,
                status: val,
            }
            that.$memberApi.memberGroupStatus(params).then(res => {
                if (res.code == 1000) {
                    that.$succMsg(res.message)
                    that.list[index].status = val
                } else {
                    that.list[index].status = val == 1 ? 0 : 1
                    that.$errMsg(res.message)
                }
            })
        },
        // 新增/编辑=确定
        submitBtn() {
            this.getList()
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
    },
}
</script>

<style lang='scss' scoped>
.account_manage {
    width: 100%;
    .account_main {
        width: 100%;
        padding: 0 20px;
        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cond_left {
                display: flex;
                align-items: center;
                .el-button {
                    padding: 8px 10px;
                    margin-right: 20px;
                }
                .el-select {
                    margin-right: 20px;
                    width: 160px;
                }
                .el-input {
                    margin-right: 20px;
                    width: 160px;
                }
            }
            .cond_right {
                .el-button {
                    padding: 8px 10px;
                }
            }
        }
    }
}
</style>
